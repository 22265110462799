import React from "react"
import { Image } from "../Image"

export const VideoItem = props => {
  const { videoId, title, image, duration } = props

  return (
    <a
      className="video-item"
      data-fancybox="video-gallery"
      // data-caption={title}
      href={`https://www.youtube.com/watch?v=${videoId}?wmode=opaque&rel=0`}
      role="button"
      tabIndex={0}
    >
      <div className="video-item__poster">
        <Image className="img" image={image} />
        <span className="video-item__duration">{duration}</span>
      </div>
      <h3 className="video-item__title">{title}</h3>
    </a>
  )
}
