import React, { useEffect } from "react"
// import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
// import { graphql, navigate } from "gatsby"

import { Layout } from "../components/Layout"

import { NewsPageLinks } from "../components/News/NewsPageLinks"
import Fancybox from "../components/Fancybox"
import { VideoItem } from "../components/Videos/VideoItem"

export default function Videos({ data }) {
  const {
    site: {
      siteMetadata: { title },
    },
    globalMetaData,
    barData,
    headerData,
    footerData,
    newsData: { metaData, tickerDuration, tickerData, videos },
  } = data

  useEffect(() => {
    if (typeof document !== "undefined") document.documentElement.scrollTop = 0
  }, [videos])

  return (
    <Layout
      barData={barData}
      headerData={headerData}
      footerData={footerData}
      tickerDuration={tickerDuration}
      tickerData={tickerData}
      ctaType="cta-inner"
      ctaDisplay={false}
      pageTitle="Video"
      title={title}
      globalMetaData={globalMetaData}
      metaData={metaData}
    >
      <div className="news-page">
        <div className="container">
          <div className="news-page-header">
            <NewsPageLinks activeTab="Video" />
          </div>
          {videos.length && (
            <div className="videos">
              <Fancybox
                options={{
                  Thumbs: false,
                  Carousel: {
                    transition: "fade",
                    infinite: true,
                  },
                  type: "iframe",
                  iframe: {
                    preload: false,
                  },
                }}
              >
                {videos.reverse().map((item, index) => (
                  <VideoItem key={index} {...item} />
                ))}
              </Fancybox>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query VideosQuery {
    site {
      siteMetadata {
        title
      }
    }
    globalMetaData: contentfulGlobalMetaData {
      desc {
        desc
      }
      keywords {
        keywords
      }
      image {
        file {
          src: url
        }
      }
    }
    barData: contentfulSectionAnnouncementBar {
      visible
      text
      link
    }
    headerData: contentfulSectionHeader {
      logo {
        file {
          src: url
        }
        alt: title
      }
      logoMobile {
        file {
          src: url
        }
        alt: title
      }
    }
    footerData: contentfulSectionFooter {
      email
      secondEmail
      social {
        icon {
          file {
            src: url
          }
          alt: title
        }
        link
      }
      newsletterTitle
      logo {
        file {
          src: url
        }
        alt: title
      }
      copyright
    }
    newsData: contentfulPageNews {
      metaData {
        title
        desc {
          desc
        }
        keywords {
          keywords
        }
        image {
          file {
            src: url
          }
        }
      }
      metaData {
        title
        desc {
          desc
        }
      }
      companies: listCompanies {
        title: name
      }
      videos {
        image: videoPoster {
          fluid(maxWidth: 400) {
            ...GatsbyContentfulFluid
          }
          file {
            src: url
          }
          alt: title
        }
        duration: videoDuration
        title: videoTitle
        videoId: youtubeVideoId
      }
      tickerDuration {
        duration
      }
      tickerData: ticker {
        text {
          text
        }
      }
    }
  }
`
